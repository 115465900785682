import React, { useState } from 'react';
import dynamic from 'next/dynamic';
import { useDispatch, useSelector } from 'react-redux';
import { toggleFormWidget } from '../../redux/actions';
import { getThemeComponent } from '@/utils/themeImports';
import { specificThemeRender } from '@/utils/specificThemeRender';
import { getLeadPopup, setLeadPopup } from '@/utils/localStorageFunctions';
import { useSSRSelector } from '@/redux/ssrStore';
import { useScrollAnchor } from './hooks/useScrollAnchor';
import { useCheckUserLoginStatus } from './hooks/useCheckUserLoginStatus';
import { useActiveLeadGenStatus } from './hooks/useActiveLeadGenStatus';
import { useInstallApp } from './hooks/useInstallApp';
import { useCartAsyncCall } from './hooks/useCartAsyncCall';
import { useSaveQueryParams } from './hooks/useSaveQueryParams';
import { useGetConditionalRenderingData } from './hooks/useGetConditionalRenderingData';
import PromosDrawerAndButton from '@/components/CouponsAndVouchers/PromosDrawerAndButton';

const ModernSideFloatingButton = dynamic(
  () =>
    import(
      '../../components/DesignSystem/ModernTheme/FloatingButton/SideFloating/SideFLoatingButton'
    )
);
const ModernScrollFloatingButton = dynamic(
  () =>
    import('../../components/DesignSystem/ModernTheme/FloatingButton/ScrollTop/ScrollTop')
);
const VariantModal = dynamic(() => import('../../components/VariantModal'));
const VariantChooseRepeatModal = dynamic(
  () => import('../../components/VariantChooseRepeatModal')
);
const VariantSelectedListModal = dynamic(
  () => import('../../components/VariantSelectedListModal')
);
const LeadGenerationModal = dynamic(() => import('../../components/LeadGenerationModal'));
const FloatingWhatsapp = dynamic(() =>
  import('../../components/FloatingWhatsapp').then((mod) => mod.FloatingWhatsapp)
);
const QuickViewModal = dynamic(
  () => import('../../components/DesignSystem/QuickViewModal')
);
const ModernForm1 = dynamic(
  () => import('../../components/DesignSystem/ModernTheme/Form/ModernForm1')
);
import { ProductCustomizationPopUp } from '@/components/ProductCustomizationPopUp';
import { FullPageLoader } from '../../components/FullPageLoader/FullPageLoader';
import { PATH } from 'src/utils/routes';

export const Home = (props) => {
  const dispatch = useDispatch();
  const [showLeadgenPopup, setShowLeadgenPopup] = useState(false);

  const { formToggleState, pdpData, personalizedProduct } = useSelector((state) => ({
    formToggleState: state.commonReducer?.toggleFormState,
    pdpData: state.catalogReducer.pdpData,
    personalizedProduct: state.catalogReducer?.personalizedProduct,
  }));
  const { storeData, widgets } = useSSRSelector((state) => ({
    storeData: state.storeReducer.store,
    widgets: state.jsonWidgetsReducer.widgets,
  }));

  useCheckUserLoginStatus();
  useScrollAnchor();
  useSaveQueryParams();
  useInstallApp(storeData);
  useCartAsyncCall(storeData);
  useActiveLeadGenStatus(storeData?.store_id, checkLeadgenPopup);

  const {
    showModernForm,
    showEnquiryForm,
    showFloatingButton,
    showFloatingWhatsappButton,
    floatingComponentData,
  } = useGetConditionalRenderingData(storeData);

  function checkLeadgenPopup() {
    if (!getLeadPopup()) setShowLeadgenPopup(true);
  }

  const handleFormClose = () => {
    dispatch(toggleFormWidget(false));
  };

  const onCloseLeadGenPop = () => {
    setLeadPopup(true);
    setShowLeadgenPopup(false);
  };

  const storeTheme = specificThemeRender(
    storeData,
    props.path?.includes(PATH.BUILDER_PREVIEW)
  );

  const {
    Header: headerComponents = [],
    Body: bodyComponents = [],
    Footer: footerComponents = [],
  } = storeTheme?.components || {};

  const jsonWidgetData = bodyComponents.find(
    (component) => component.name === 'JsonPageWidget'
  );

  return (
    <section className="preHome-page">
      {headerComponents.slice(0, 1)?.map((layout) => {
        return getThemeComponent(layout.sub_type, layout.name, layout);
      })}
      {jsonWidgetData
        ? getThemeComponent(jsonWidgetData.sub_type, jsonWidgetData.name, {
            ...jsonWidgetData,
            widgets: widgets || {},
            storeData: storeData,
          })
        : bodyComponents.map((layout) => {
            layout.banner_fixed_ratio = storeData?.theme?.banner_fixed_ratio;
            return getThemeComponent(layout.sub_type, layout.name, {
              showATCBtn: true,
              ...layout,
              isLink: true,
            });
          })}
      {footerComponents.map((layout) => {
        return getThemeComponent(layout.sub_type, layout.name, layout);
      })}
      <FullPageLoader />
      <VariantModal />
      <VariantChooseRepeatModal />
      <VariantSelectedListModal />
      {pdpData && pdpData?.visible && <QuickViewModal />}
      {showLeadgenPopup && (
        <LeadGenerationModal show={showLeadgenPopup} onClose={onCloseLeadGenPop} />
      )}
      {showModernForm && (
        <ModernForm1 open={formToggleState} handleClose={handleFormClose} />
      )}
      {showEnquiryForm && (
        <ModernSideFloatingButton {...floatingComponentData} inBuilder={false} />
      )}
      {showFloatingButton && (
        <ModernScrollFloatingButton {...floatingComponentData} inBuilder={false} />
      )}
      {showFloatingWhatsappButton && <FloatingWhatsapp />}
      {personalizedProduct?.personalizationPopUp && <ProductCustomizationPopUp />}
      <PromosDrawerAndButton />
    </section>
  );
};
